<template>
  <!-- 
    画面: キャンセル規定編集
    用途: キャンセル規定を更新または削除する
   -->
  <div class="crud-single-content">
    <!-- #region Main -->
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader
            ><h3 class="card-title">
              {{ $t("cancelPolicyEditPage.pageTitle") }}
            </h3>
          </CCardHeader>

          <CCardBody>
            <span class="caption">
              {{ $t("cancelPolicyEditPage.caption") }}
            </span>
            <CForm class="submit_form">
              <!-- #region 入力フィールド -->

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"
                  ><strong class="required-mark">{{
                    $t("cancelPolicyEditPage.name")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput v-model="edit.name" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("cancelPolicyEditPage.name"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.maxLength"
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("cancelPolicyEditPage.name"),
                        vMax: $v.edit.name.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"
                  ><strong>{{
                    $t("cancelPolicyEditPage.description")
                  }}</strong></CCol
                >
                <CCol>
                  <CTextarea class="mb-2" v-model="edit.description" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.description.$dirty &&
                      !$v.edit.description.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("cancelPolicyEditPage.description"),
                        vMax: $v.edit.description.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->
            </CForm>
          </CCardBody>
          <!-- #endregion -->
          <!-- #region CancelSettings -->
          <CCardHeader>
            <h3 class="card-title">
              {{ $t("cancelPolicyEditPage.cancelRateSetting") }}
            </h3>
          </CCardHeader>
          <CCardBody>
            <table
              class="
                edge-type-table
                table table-sm table-striped table-bordered
              "
            >
              <thead class="bg-light text-body">
                <tr>
                  <th width="240">
                    {{ $t("cancelPolicyEditPage.cancelDayStartEnd") }}
                  </th>
                  <th width="240">
                    {{ $t("cancelPolicyEditPage.cancelRate") }}
                  </th>
                  <th width="">{{ $t("cancelPolicyEditPage.description") }}</th>
                  <th width="150"></th>
                </tr>
              </thead>
              <transition-group type="transition" name="flip-list" tag="tbody">
                <tr v-for="item in tableItems" :key="item.id">
                  <!-- #region 有効か？ -->

                  <!-- #endregion 有効か？ -->

                  <!-- #region 宿泊日までの日数 -->
                  <td>
                    <ul class="ul-line-block">
                      <li>
                        <CInput
                          class="w-3"
                          v-model="item.dayStart"
                          placeholder="0"
                          type="number"
                          append="日"
                          @update:value="updateTable()"
                        />
                      </li>
                      <li>~</li>
                      <li>
                        <CInput
                          class="w-3"
                          type="number"
                          v-model="item.dayEnd"
                          placeholder="0"
                          append="日"
                          @update:value="updateTable()"
                        />
                      </li>
                    </ul>
                  </td>
                  <!-- #endregion 宿泊日までの日数 -->

                  <!-- #region キャンセル料（％） -->
                  <td>
                    <CRow>
                      <CCol class="">
                        <CInput
                          class="w-6 mx-auto"
                          type="number"
                          v-model="item.cancelRate"
                          placeholder="0"
                          append="％"
                          @update:value="updateTable()"
                        />
                      </CCol>
                    </CRow>
                  </td>
                  <!-- #endregion キャンセル料（％） -->

                  <!-- #region 説明 -->
                  <td>
                    <CInput
                      v-model="item.description"
                      placeholder="説明"
                      @update:value="updateTable()"
                    />
                  </td>
                  <!-- #endregion 説明 -->

                  <!-- #region 操作 -->
                  <td>
                    <template v-if="!isDeleteButtonDisabled">
                      <CButton
                        @click="onSettingRowDeleteClicked(item.id, tableItems)"
                        color="danger"
                        class="px-3 py-0 ml-2"
                      >
                        <strong>ー</strong>
                      </CButton>
                    </template>
                    <CButton
                      color="info"
                      class="px-3 py-0 ml-2"
                      @click="onSettingRowAddClicked(item.id, tableItems)"
                      ><i class="icon cil-plus"></i
                    ></CButton>
                  </td>
                  <!-- #endregion 操作 -->
                </tr>
              </transition-group>
            </table>
          </CCardBody>

          <!-- #endregion CancelSettings -->

          <!-- #region プレビュー -->
          <CCardHeader>
            <h3 class="card-title">{{ $t("cancelPolicyEditPage.preview") }}</h3>
          </CCardHeader>
          <CCardBody class="profile-home px-5">
            <div class="user-info-table mx-2">
              <dl class="row"></dl>

              <table
                class="table border table-sm crud-input-table table-striped"
              >
                <tr>
                  <td><span v-html="previewExample()"></span></td>
                </tr>
              </table>
            </div>
          </CCardBody>

          <!-- #endregion プレビュー -->

          <!-- #region 更新ボタン -->
          <CCardBody>
            <CButton
              class="px-5 mt-4 float-left"
              color="info"
              @click="onUpdateClicked()"
              v-bind:class="activeStatus"
            >
              <i class="icon cil-task"></i> {{ $t("common.update") }}
            </CButton>
            <!-- #endregion 更新ボタン -->

            <!-- #region 削除ボタン -->
            <CButton
              class="px-5 mt-4 float-right"
              color="danger"
              @click="onDeleteClicked()"
            >
              <i class="icon cil-trash"></i> {{ $t("common.delete") }}
            </CButton>
            <!-- #endregion 削除ボタン -->
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              class="px-3 float-left"
              @click="onBackClicked()"
            >
              <i class="icon cil-arrow-left mr-2"></i>
              {{ $t("common.back") }}</CButton
            >
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

    <!-- #region Modal -->

    <DeleteConfirmModal
      :deleteConfirmModal="deleteConfirmModal"
      :callback="reqDelete"
      @close="deleteConfirmModal = $event"
    />

    <SuccessModal :successModal="successModal" @close="successModal = $event" />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

    <!-- #endregion Modal -->
  </div>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";
import SuccessModal from "@/components/SuccessModal";
import ErrorModal from "@/components/ErrorModal";
import DeleteConfirmModal from "@/components/DeleteConfirmModal";
import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "CancelPolicyEdit",

  props: {},
  components: {
    SuccessModal,
    ErrorModal,
    DeleteConfirmModal,
  },

  computed: {
    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true; // TODO: 開発用
    },
    /** ボタン有効か？ */
    activeStatus() {
      //console.log(this.$v);
      return this.$v.edit.$invalid ? "inactive" : "active";
    },
    /** 宿UniqueID */
    uid() {
      return this.$store.state.uniqueId;
    },
    isDeleteButtonDisabled() {
      return this.tableItems.length <= 1;
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,

      //#endregion Flag

      //#region Message
      errorMessage: "error",
      //#endregion Message

      //#region Table

      /** 一覧表データ */
      tableSource: "",
      tableItems: [],

      initialData: [
        {
          id: 1,
          startDuration: 0,
          endDuration: 0,
          percentage: 0,
          description: "",
        },
      ],
      //#endregion Table

      //#region Input

      edit: {
        name: "",
        description: "",
        /** キャンセル料一覧表 */
        cancelTable: "",
      },

      //#endregion Input

      //#region Response data

      response: [],

      //#endregion Response data

      cancelTableSetting: [],
    };
  },

  validations() {
    return {
      edit: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
      tableItems: {},
    };
  },

  methods: {
    //#region ----- Event -----

    /** キャンセル料設定テーブルプラスボタン押下 */

    onSettingRowAddClicked(id, tableItems) {
      const nextId = this.getNextSettingRowId(id);
      tableItems.forEach((a) => {
        if (a.id < nextId) return;
        a.id = a.id + 1;
      });
      tableItems.splice(id, 0, this.createNewSettingRow(nextId));
      tableItems.sort((a, b) => a.id - b.id);
      this.updateTable();
      console.log(this.tableItems);
    },

    /** キャンセル料設定テーブルマイナスボタン押下 */

    onSettingRowDeleteClicked(id, tableItems) {
      const nextId = this.getNextSettingRowId(id);
      tableItems.some((v, i) => {
        if (v.id == id) {
          tableItems.splice(i, 1);
        }
      });
      tableItems.forEach((a) => {
        if (a.id < nextId) return;
        a.id = a.id - 1;
      });
      tableItems.sort((a, b) => a.id - b.id);
      this.updateTable();
      console.log(this.tableItems);
    },

    /** 戻るボタン押下 */
    onBackClicked() {
      this.$router.push({
        path: `/operator/cancelPolicy/list`,
      });
    },

    /** 削除ボタン押下 */
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    /** 削除ボタン押下 */
    onDeleteSubmit() {
      this.reqDelete();
    },

    /** 更新ボタン押下 */
    onUpdateClicked() {
      this.reqPut();
    },

    /** 表適用ボタン押下 */
    onUpdateTableClicked() {
      // this.edit.cancelTable = this.updateTable();
      this.updateTable();
    },

    //#endregion ----- Event -----

    //#region ----- Request -----

    /**
     * キャンセル規定削除
     */
    reqDelete() {
      this.deleteConfirmModal = false;
      this.successModal = true;

      const cancelpolicyid = this.$route.params.cancelPolicyId;
      const url = `/rest/hotel/${this.uid}/cancelpolicy/${cancelpolicyid}`;
      console.log(url);

      axios
        .delete(url, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));

          this.successModal = true;
        })
        .catch((err) => {
          this.errorMessage = err + "'  post to facility '";
          this.errorModal = true;
        });
    },

    reqPut() {
      var cancelPolicyId = this.$route.params.cancelPolicyId;
      var url = `/rest/hotel/${this.uid}/cancelpolicy/${cancelPolicyId}`;
      let reqPolicyItems = [];

      this.tableItems.forEach((a) =>
        reqPolicyItems.push({
          id: a.id,
          startDuration: a.dayStart,
          endDuration: a.dayEnd,
          percentage: a.cancelRate,
          policyId: cancelPolicyId,
          description: a.description,
        })
      );
        let reqData = {        id: cancelPolicyId,
        hotelId: this.uid,
        name: this.edit.name,
        description: this.edit.description,
        policyItems: reqPolicyItems,};

      console.log(url);
      console.log(reqData);

      axios
        .put(url, reqData, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          this.successModal = true;
          this.getCancelPolicy();
          console.log(resp);
        })
        .catch((err) => {
          this.errorMessage = err + "'  post to facility '";
          this.errorModal = true;
          console.log(err);
        });
    },

    getCancelPolicy() {
      var cancelPolicyId = this.$route.params.cancelPolicyId;
      const url = `/rest/hotel/${this.uid}/cancelpolicy/${cancelPolicyId}`;
      console.log("test" + url);
      axios
        .get(url, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          const datas = resp.data.policyItems;
          //テーブルデータが存在していない場合データを初期化します。
          if (datas.length <= 0) {
            this.pourTable(this.initialData);
          } else {
            this.pourTable(datas);
          }

          // 取得データを一覧表に流し込み
          this.edit.name = resp.data.name;
          this.edit.description = resp.data.description;
          this.loading = false;
          this.updateTable();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    //#endregion ----- Request -----

    //#region ----- Method -----

    /** キャンセル料設定テーブルから一覧表にデータを入れる */
    updateTable() {
      let html = ``;
      html += `<table>`;
      html += `<thead>`;
      html += ` <tr>`;
      html += `<th>`;
      html += `${i18n.t("cancelPolicyEditPage.cancelDayStartEnd")}`;
      html += `</th>`;
      html += `<th>`;
      html += `${i18n.t("cancelPolicyEditPage.cancelRate")}`;
      html += `</th>`;
      html += `<th>`;
      html += `${i18n.t("cancelPolicyEditPage.descriptionPreview")}`;
      html += `</th>`;
      html += ` </tr>`;
      html += `</thead>`;
      html += `<tbody>`;
      for (const cancellationData of this.tableItems) {
        html += ` <tr>`;
        html += `   <th>${cancellationData.dayStart}日～${cancellationData.dayEnd}日</th>`;
        html += `   <th>${cancellationData.cancelRate}%</th>`;
        html += `   <th>${cancellationData.description}</th>`;
        html += ` </tr>`;
      }

      html += `<tbody>`;
      html += `</table>`;

      // console.log(html);

      // return html;
      this.edit.cancelTable = html;
    },

    /**htmlプレビュー機能 */
    previewExample() {
      let html = ``;
      html += `<h3>【`;
      html += `${i18n.t("cancelPolicyEditPage.cancelInformation")}`;
      html += `】</h3>`;
      html += `<p>${this.edit.description}</p>`;
      html += `<div>${this.edit.cancelTable}</div>`;

      return html;
    },

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.tableItems.push({
          id: data.id,
          dayStart: data.startDuration,
          dayEnd: data.endDuration,
          cancelRate: data.percentage,
          policyId: data.policyId,
          description: data.description,
        });
      });
      this.tableItems.sort((a, b) => a.id - b.id);
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    /** 行を追加メソッド */
    createNewSettingRow(id) {
      return {
        id: id,
        dayStart: 0,
        dayEnd: 0,
        cancelRate: 0,
        description: "",
      };
    },

    /** 次の行のIDを取得 */
    getNextSettingRowId(id) {
      return id + 1;
    },

    //#endregion ----- Method -----
  },
  beforeMount() {
    this.getCancelPolicy();
  },
};
</script>
